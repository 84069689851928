// Fonts for UP Core Vogue
$font-stack-primary: 'Untitled Sans', Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans', Arial, sans-serif;
$font-stack-headings: 'Untitled Serif', Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif; 

// General Breakpoints
$breakpoints: (
    'XXS': (max-width: 30em),
    'XS': ( max-width:  40em ),
    'S': ( max-width:  48em ),
    'MSS': ( max-width:  53em ),
    'MS': ( max-width:  59em ),
    'M': ( max-width:  64em ),
    'ML': ( max-width:  75em ),
    'MML': ( max-width:  85em ),
    'L': ( max-width: 91em ),

    'XXS-up': (min-width: calc(30em + 1px)),
    'XS-up': (min-width: calc(40em + 1px)),
    'S-up': (min-width: calc(48em + 1px)),
    'MSS-up': (min-width: calc(53em + 1px)),
    'MS-up': (min-width: calc(59em + 1px)),
    'M-up': (min-width: calc(64em + 1px)),
    'ML-up': (min-width: calc(75em + 1px)),
    'MML-up': (min-width: calc(85em + 1px)),
    'L-up': (min-width: calc(91em + 1px)),

    'XL': ( min-width: 91em ),
    'XXL': ( min-width: 105em ),
    'XXXL': ( min-width: 112em ),

    'Rooms': (max-width: 70.625rem),
    'Rooms-up': ( min-width: calc(70.625rem + 1px))
);
